import { render, staticRenderFns } from "./AchievementList.vue?vue&type=template&id=33ab4230&scoped=true"
import script from "./AchievementList.vue?vue&type=script&lang=js"
export * from "./AchievementList.vue?vue&type=script&lang=js"
import style0 from "./AchievementList.vue?vue&type=style&index=0&id=33ab4230&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ab4230",
  null
  
)

export default component.exports